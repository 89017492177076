/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";

import { useHistory } from "react-router-dom";

import { shallowEqual, useSelector } from "react-redux";

export function QuickUser() {
  const { datosUsuario } = useSelector(
    (state) => ({
      datosUsuario: state.auth.user,
    }),
    shallowEqual
  );
  //console.log("Usuario: ", datosUsuario);
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          Mi Perfil
          <small className="text-muted font-size-sm ml-2"></small>
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          {/*             <div
                className="symbol symbol-100 mr-5"
            >
              <div className="symbol-label" style={{
                backgroundImage: `url(${toAbsoluteUrl(
                    "/media/users/300_21.jpg"
                )})`
              }}/>
              <i className="symbol-badge bg-success"/>
            </div> */}
          <div className="d-flex flex-column">
            <div className=" pb-10  ">
              Cuenta de Inversión: <strong>{datosUsuario.tipoCuenta}</strong>
              <br />
              Titular: <strong>{datosUsuario.fullname}</strong>
              <br />
              Cuenta No. <strong>{datosUsuario.numCuenta}</strong>
              <br />
              Email: <strong>{datosUsuario.email}</strong>
              <br />
              País: <strong>{datosUsuario.pais}</strong>
              <br />
              Equipo Asesor:
              <br />
              <strong style={{ whiteSpace: "pre-wrap" }}>
                {datosUsuario.equipoAsesor}
              </strong>
              <br />
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold "
              onClick={logoutClick}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
