/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../app/modules/ECommerce/_redux/transactions/transactionsActions";

export function ListsWidget9({ className }) {
  /////

  // Getting curret state of transactions list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.transactions }),
    shallowEqual
  );

  const { entities } = currentState;

  //console.log(entities);

  let queryParams = {
    filter: {
      status: "",
      tipoOpcion: "",
      idContrato: "",
    },
    sortOrder: "desc", // asc||desc
    sortField: "updated_at",
    pageNumber: 1,
    pageSize: 10,
  };

  // Transactions Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchTransactions(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  ///////
  let timelinetrans;
  if (entities) {
    timelinetrans = entities.map((entity) => (
      <div className="timeline-item align-items-start" key={entity.id}>
        <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
          {format(new Date(entity.updated_at), "ccc kk:mm", {
            locale: esLocale,
          })}
        </div>

        <div className="timeline-badge">
          {entity.aprobado ? (
            <i className="fa fa-check text-success icon-l"></i>
          ) : (
            <i className="fa fa-clock text-warning icon-l"></i>
          )}
        </div>

        <div className="timeline-content d-flex">
          <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
            {entity.Tipo}
            <span className="timeline-content font-weight-mormal font-size-lg text-muted pl-3">
              {`${entity.referencia} US$ ${entity.monto.toLocaleString()}`}
            </span>
          </span>
        </div>
      </div>
    ));
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Últimas transacciones
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Por fecha de actualización
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3">{timelinetrans}</div>
        </div>
      </div>
    </>
  );
}
