import axios from "axios";

export const REGISTER_URL = "api/auth/register";

//desarrollo
//export const LOGIN_URL = "http://localhost:1337/auth/local";
//export const REQUEST_PASSWORD_URL =
//  "http://localhost:1337/auth/forgot-password";
//export const ME_URL = "http://localhost:1337/users/me";

//produccion

export const LOGIN_URL = "https://admin.aefmtrade.com/auth/local";
export const REQUEST_PASSWORD_URL =
  "https://admin.aefmtrade.com/auth/forgot-password";
export const ME_URL = "https://admin.aefmtrade.com/users/me";

export async function login(email, password) {
//  const res = await axios.get("https://geolocation-db.com/json/");
//  const ip = res.data.IPv4;
//  const country_name = res.data.country_name;
//  console.log(ip, "LOssGGIN", country_name);
  /* HAY QUE CAMBIARLO EN PROD */
  await axios.post("https://admin.aefmtrade.com/logs/", {
    user: email,
    ip: "192.168.0.1",
    pais: "CR",
  });

  return axios.post(LOGIN_URL, { identifier: email, password: password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.

  return axios.get(ME_URL);
}
