/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";

import { shallowEqual, useSelector } from "react-redux";

import { useHtmlClassService } from "../../../layout";

import { KTUtil } from "../../../_assets/js/components/util";

export function MixedWidget14({ className }) {
  const {
    inversion = 0,
    disponible = 0,
    credito = 0,
    activas = 0,
  } = useSelector(
    (state) => ({
      credito: state.transactions.credito,
      activas: state.products.activas,
      inversion: state.transactions.inversion,
      disponible: state.transactions.disponible,
    }),
    shallowEqual
  );
  //console.log(inversion, disponible, credito, activas);
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_14_chart");
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(
      layoutProps,
      height,
      inversion,
      disponible,
      credito,
      activas
    );

    const chart = new ApexCharts(element, options);

    chart.render();

    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, inversion, disponible, credito, activas]);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title font-weight-bolder ">Balance</h3>
      </div>
      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div id="kt_mixed_widget_14_chart" style={{ height: "300px" }}></div>
        </div>
        {/*         <div className="pt-5">
          <p className="text-center font-weight-normal font-size-lg pb-7">
            Notes: Current sprint requires stakeholders
            <br />
            to approve newly amended policies
          </p>
          <a
            href="#"
            className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3"
          >
            Imprimir Estado de Cuenta
          </a>
        </div> */}
      </div>
    </div>
  );
}

function getChartOptions(
  layoutProps,
  height,
  inversion,
  disponible,
  credito,
  activas
) {
  const options = {
    series: [inversion, disponible, credito, activas],
    chart: {
      height: height,
      type: "pie",
    },
    plotOptions: {
      pie: {
        hollow: {
          margin: 0,
          size: "65%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            /* color: layoutProps.colorsGrayGray700, */
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true,
          },
        },
        track: {
          background: layoutProps.colorsThemeLightSuccess,
          strokeWidth: "100%",
        },
      },
    },
    /* colors: [layoutProps.colorsThemeBaseSuccess], */
    stroke: {
      lineCap: "round",
    },
    labels: ["Inv. Realizada", "Disponible", "Crédito", "Inv. Activas"],
    legend: {
      position: "bottom",
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
          return "$" + value + ".00";
        },
      },
    },
  };
  return options;
}
