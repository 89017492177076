import * as requestFromServer from "./transactionsCrud";
import { transactionsSlice, callTypes } from "./transactionsSlice";

const { actions } = transactionsSlice;

export const fetchTransactions = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTransactions(queryParams)
    .then((response) => {
      const { totalCount, entities } = response.data;
      //console.log(response.data);
      dispatch(actions.transactionsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transactions";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTransaction = (id) => (dispatch) => {
  if (!id) {
    //console.log("response.data");
    return dispatch(
      actions.transactionFetched({ transactionForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTransactionById(id)
    .then((response) => {
      const transaction = response.data;

      dispatch(actions.transactionFetched({ transactionForEdit: transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransaction = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransaction(id)
    .then((response) => {
      dispatch(actions.transactionDeleted({ id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTransaction = (transactionForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createTransaction(transactionForCreation)

    .then((response) => {
      //console.log(response.data);
      const { transaction } = JSON.parse(response.data);

      dispatch(actions.transactionCreated({ transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't create transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransaction = (transaction) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTransaction(transaction)
    .then(() => {
      dispatch(actions.transactionUpdated({ transaction }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTransactionsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForTransactions(ids, status)
    .then(() => {
      dispatch(actions.transactionsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update transactions status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteTransactions = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteTransactions(ids)
    .then(() => {
      dispatch(actions.transactionsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete transactions";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

//////

export const fetchInversion = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getInversion()
    .then((response) => {
      const _inv = response.data;

      dispatch(actions.inversionFetched({ inversion: _inv }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchDisponible = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDisponible()
    .then((response) => {
      const _inv = response.data;

      dispatch(actions.disponibleFetched({ disponible: _inv }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCredito = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCredito()
    .then((response) => {
      const _inv = response.data;

      dispatch(actions.creditoFetched({ credito: _inv }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find transaction";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

/////
