import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Spinner } from "react-bootstrap";


export default function RecomendacionWP() {
  const url =
    "https://inversiones-ny.com/wp-json/wp/v2/posts?categories=28&per_page=1&_embed";

  const [posts, setPosts] = useState(0);

  useEffect(() => {
    Axios.get(url).then((response) => {
      setPosts(response.data);
    });
  }, []);
  //console.log("recomenda", posts);
  return (
    <>
      {posts ? (
        <>
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">Recomendación</span>
          </h3>
          <img
            src={
              posts.length &&
              posts[0]._embedded["wp:featuredmedia"][0].media_details.sizes[
                "blog-medium"
              ].source_url
            }
            alt={posts.length && posts[0].title.rendered}
          ></img>
          <h4 className="align-items-start flex-column pt-5">
            <span className="font-weight-bolder text-dark">
              {posts.length && posts[0].title.rendered}
            </span>
          </h4>
          <div
            className="text-dark-50 m-0  font-weight-normal"
            dangerouslySetInnerHTML={{
              __html: posts.length && posts[0].excerpt.rendered + "...",
            }}
          />
          <a
            href={posts.length && posts[0].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Leer más
          </a>
        </>
      ) : (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </>
  );
}
