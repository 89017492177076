/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

import NoticiasWP from "../noticiaswp/noticiaswp";
import RecomendacionWP from "../noticiaswp/recomendacionwp";

export function StatsWidget11({ className, pos }) {
  /* const uiService = useHtmlClassService(); */

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <div className="d-flex flex-column mr-2">
            {/*              */}
            {pos < 2 ? <NoticiasWP pos={pos} /> : <RecomendacionWP />}
          </div>
        </div>
      </div>
    </div>
  );
}
