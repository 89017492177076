/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useIdleTimer } from "react-idle-timer";

export function Routes() {
  const timeout = 60000;
  const [remaining, setRemaining] = useState(timeout);
  const { getRemainingTime } = useIdleTimer({
    timeout,
  });

  useEffect(() => {
    let mounted = true;
    setInterval(() => {
      if (
        mounted &&
        window.location.pathname !== "/auth/login" &&
        window.location.pathname !== "/logout"
      ) {
        setRemaining(getRemainingTime());
      }
    }, 1000);
    return () => {
      mounted = false;
    };
  }, []);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const history = useHistory();
  const expiredLogout = () => {
    setRemaining(timeout);
    history.push("/logout");
  };

  console.log(remaining, window.location.pathname !== "/auth/login");

  return (
    <Switch>
      {remaining === 0 ? expiredLogout() : null}
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
          {remaining < timeout - 10000 ? (
            <>
              La sesión vence dentro de {parseInt(remaining / 1000)} segundos
              por inactividad
            </>
          ) : null}
        </Layout>
      )}
    </Switch>
  );
}
