import axios from "axios";

// export const TRANSACTIONS_URL = "http://localhost:1337/transaccions"; //desarrollo
export const TRANSACTIONS_URL = "https://admin.aefmtrade.com/transaccions"; //produccion

// CREATE =>  POST: add a new transaction to the server
export function createTransaction(transaction) {
  return axios.post(TRANSACTIONS_URL, { transaction });
}

// READ
export function getAllTransactions() {
  return axios.get(TRANSACTIONS_URL);
}

export function getTransactionById(transactionId) {
  return axios.get(`${TRANSACTIONS_URL}/${transactionId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findTransactions(queryParams) {
  //console.log(queryParams);
  return axios.post(`${TRANSACTIONS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the procuct on the server
export function updateTransaction(transaction) {
  return axios.put(`${TRANSACTIONS_URL}/${transaction.id}`, { transaction });
}

// UPDATE Status
export function updateStatusForTransactions(ids, status) {
  return axios.post(`${TRANSACTIONS_URL}/updateStatusForTransactions`, {
    ids,
    status,
  });
}

// DELETE => delete the transaction from the server
export function deleteTransaction(transactionId) {
  return axios.delete(`${TRANSACTIONS_URL}/${transactionId}`);
}

// DELETE Transactions by ids
export function deleteTransactions(ids) {
  return axios.post(`${TRANSACTIONS_URL}/deleteTransactions`, { ids });
}

// obtenr inversion total (suma de depositos)
export function getInversion() {
  return axios.get(`${TRANSACTIONS_URL}/getinversion`);
}

// obtenr disponible (suma de depositos,ventas y creditos menos retiros ventas y comisiones)
export function getDisponible() {
  return axios.get(`${TRANSACTIONS_URL}/getdisponible`);
}

// obtenr disponible (suma de depositos,ventas y creditos menos retiros ventas y comisiones)
export function getCredito() {
  return axios.get(`${TRANSACTIONS_URL}/getcredito`);
}
