/* import { getInversion } from "../../../../../src/app/modules/ECommerce/_redux/transactions/transactionsCrud"; */
import {
  fetchInversion,
  fetchDisponible,
  fetchCredito,
} from "../../../app/modules/ECommerce/_redux/transactions/transactionsActions";
import { fetchActivas } from "../../../app/modules/ECommerce/_redux/products/productsActions";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

export function InversionTotal() {
  const { balance } = useSelector(
    (state) => ({
      balance: state.transactions.inversion,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchInversion());
  }, [dispatch]);

  if (balance >= 0) {
    return formatMoney(balance);
  } else {
    return 0;
  }
}

export function Disponible() {
  const { balance2 } = useSelector(
    (state) => ({
      balance2: state.transactions.disponible,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDisponible());
  }, [dispatch]);

  if (balance2 >= 0) {
    return formatMoney(balance2);
  } else {
    return 0;
  }
}

export function InversionesActivas() {
  const { balance3 } = useSelector(
    (state) => ({
      balance3: state.products.activas,
    }),
    shallowEqual
  );
  //console.log("credito", formatMoney(balance3));
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchActivas());
  }, [dispatch]);

  if (balance3 >= 0) {
    return formatMoney(balance3);
  } else {
    return 0;
  }
}

export function PosicionesCredito() {
  const { balance4 } = useSelector(
    (state) => ({
      balance4: state.transactions.credito,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCredito());
  }, [dispatch]);

  if (balance4 >= 0) {
    return formatMoney(balance4);
  } else {
    return 0;
  }
}

/* export function PosicionesCredito() {
  const { balance2 } = useSelector(
    (state) => ({
      balance2: state.transactions.disponible,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDisponible());
  }, [dispatch]);

  if (balance2 < 0) {
    return formatMoney(balance2);
  } else {
    return 0;
  }
} */

////////////

function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {}
}
