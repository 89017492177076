import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Spinner } from "react-bootstrap";  

export default function NoticiasWP(props) {
  const url =
    "https://inversiones-ny.com//wp-json/wp/v2/posts?categories=1&per_page=2&_embed";

  const [posts, setPosts] = useState(0);

  useEffect(() => {
    Axios.get(url).then((response) => {
      setPosts(response.data);
    });
  }, []);
  //console.log("posts: ", props);
  return (
    <>
      {posts && props.pos < 2 ? (
        <>
          <img
            src={
              posts.length &&
              posts[props.pos]._embedded["wp:featuredmedia"][0].media_details
                .sizes["blog-medium"].source_url
            }
            alt={posts.length && posts[props.pos].title.rendered}
          ></img>
          <h4 className="align-items-start flex-column pt-5">
            <span className="font-weight-bolder text-dark">
              {posts.length && posts[props.pos].title.rendered}
            </span>
          </h4>
          {/*           <div
            className="text-dark-50 m-0  font-weight-normal"
            dangerouslySetInnerHTML={{
              __html:
                posts.length &&
                posts[props.pos].excerpt.rendered.substring(0, 100) + "...",
            }}
          /> */}

          <a
            href={posts.length && posts[props.pos].link}
            target="_blank"
            rel="noopener noreferrer"
          >
            Leer más
          </a>
        </>
      ) : (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      )}
    </>
  );
}
