/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React from "react";
import SVG from "react-inlinesvg";
/* import { Dropdown } from "react-bootstrap"; */
import { toAbsoluteUrl } from "../../../_helpers";
/* import { DropdownMenu2 } from "../../dropdowns"; */
import * as balances from "../../../layout/components/balances";
import { shallowEqual, useSelector } from "react-redux";

export function MixedWidget1({ className }) {
  const { datosUsuario } = useSelector(
    (state) => ({
      datosUsuario: state.auth.user,
    }),
    shallowEqual
  );
  //console.log("Usuario: ", datosUsuario);
  return (
    <div className={`card card-custom bg-gray-100 ${className}`}>
      {/* Header */}
      {/*       <div
        className="card-header border-0  py-5"
        style={{
          backgroundColor: "#4d74aa",
        }}
      >
        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              className="btn btn-transparent-white btn-sm font-weight-bolder dropdown-toggle px-5"
              variant="transparent"
              id="dropdown-toggle-top"
            >
              Imprimir
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu2 />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div> */}
      {/* Body */}
      <div className="card-body p-0  position-relative overflow-hidden">
        {/* Chart */}
        <div
          /*  id="kt_mixed_widget_1_chart" */
          className="card-rounded p-10 "
          style={{
            backgroundImage: "url(../../../../../media/logos/logo-aia.png",
            minHeight: "250px",
            backgroundPositionX: "center",
            /*  backgroundSize: "230px", */
            backgroundRepeat: "no-repeat",
            /*  backgroundBlendMode: "difference", */
            backgroundColor: "#4d74aa",
            backgroundSize: "cover",
          }}
        >
          <h6 className="card-title font-weight-bold text-white">
            Cuenta de Inversión: {datosUsuario.tipoCuenta}
          </h6>
          <h6 className="card-title font-weight-bold text-white">
            Titular: {datosUsuario.fullname}
          </h6>
          <h6 className="card-title font-weight-bold text-white">
            Cuenta No. {datosUsuario.numCuenta}
          </h6>
        </div>

        {/* Stat */}
        <div className="card-spacer mt-n25">
          <div className="row m-0">
            <div className="col bg-gray-200 px-6 py-8 rounded-xl mr-7 mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}
                ></SVG>
              </span>
              Inversión Realizada <br />
              <a
                href="#"
                className="text-warning font-weight-bold font-size-h6"
              >
                $<balances.InversionTotal />
              </a>
            </div>
            <div className="col bg-gray-200  px-6 py-8 rounded-xl mb-7">
              <span className="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                ></SVG>
              </span>
              Fondos Disponibles <br />
              <a
                href="#"
                className="text-primary font-weight-bold font-size-h6 mt-2"
              >
                $<balances.Disponible />
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div
              className="col bg-gray-200  px-6 py-8 rounded-xl mr-7"
              style={{
                backgroundColor: "#4d74aa",
              }}
            >
              <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                ></SVG>
              </span>
              Invesiones Activas <br />
              <a
                href="#"
                className="text-success font-weight-bold font-size-h6 mt-2"
              >
                $<balances.InversionesActivas />
              </a>
            </div>
            <div className="col bg-gray-200  px-6 py-8 rounded-xl">
              <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Urgent-mail.svg"
                  )}
                ></SVG>
              </span>
              Pocisiones en Crédito
              <br />
              <a
                href="#"
                className="text-danger font-weight-bold font-size-h6 mt-2"
              >
                $<balances.PosicionesCredito />
              </a>
            </div>
          </div>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}
