/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import * as actions from "../../../../app/modules/ECommerce/_redux/products/productsActions";

export function CommoditiesWidget({ className }) {
  /////

  // Getting curret state of transactions list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.products }),
    shallowEqual
  );
  //console.log(currentState);
  const { entities } = currentState;

  //console.log(entities);

  let queryParams = {
    filter: {
      status_contrato: "Activo",
    },
    sortOrder: "desc", // asc||desc
    sortField: "updated_at",
    pageNumber: 1,
    pageSize: 10,
  };

  // Transactions Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchProducts(queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  ///////
  let color = "success";
  let timelinetrans;
  if (entities) {
    timelinetrans = entities.map((entity) => (
      <div className="timeline-item align-items-center" key={entity.id}>
        {entity.valorActual / entity.montoTotal >= 1 ? (
          <span className="label label-lg label-success label-inline col-lg-2">
            {((entity.valorActual / entity.montoTotal) * 100 - 100).toFixed(0)}%
          </span>
        ) : (
          <span className="label label-lg label-danger label-inline col-lg-2">
            {((entity.valorActual / entity.montoTotal) * 100 - 100).toFixed(0)}%
          </span>
        )}

        <div className="d-flex">
          <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
            <span className=" font-weight-mormal font-size-lg text-muted ">
              {`${entity.idContrato}`}
            </span>
            <br />
            <span>
              {`${entity.cantidad} ${entity.commodity.symbol} ${
                entity.tipoOpcion
              }
              US$ ${entity.strikePrice.toLocaleString()}`}
            </span>
          </span>
        </div>
      </div>
    ));
  }

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">
              Portafolio Inversiones Activas
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Contratos Apalancados
            </span>
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-2">{timelinetrans}</div>
        </div>
      </div>
    </>
  );
}
